<template>
    <div class="loginPage">
        <Header :height="250" />
        <div class="main">
            <div class="select">
                <button @click="Hours" class="button">Hour Records</button>
            </div>
            <div class="select">
                <button @click="Score" class="button">Scores </button>
            </div>

        </div>
    </div>
</template>

<script>
import Header from '../components/Header.vue';
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';

export default {
    name: "Select",
    components: {
        Header,
        // Input,
        //Button
    },
    setup() {
        const router = useRouter();

        onMounted(() => {
            const aux = localStorage.getItem('setRole')

            const role = JSON.parse(aux)

            if (role === 5) {
                router.push('/score_client')
            }
        })


        const Score = () => {
            router.push({ path: "/score_adm" });
        }

        const Hours = () => {
            router.push({ path: "/" });
        }


        return {
            Score,
            Hours,
        }
    },
}
</script>

<style scoped>
.loginPage {
    background-color: #E5E5E5;
    height: 100vh;
    overflow: auto;
}

.inputContainer {
    width: 80%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin: 0 auto;
}

.title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #333333;
}

.doubts {
    color: #2f90b0;
    font-weight: 600;
    font-size: .875rem;
    cursor: pointer;
}

.button {
    box-sizing: border-box;
    background-color: #2f90b0;
    border-radius: 30px;
    padding: .8rem 0;
    color: #fff;
    width: 95%;
    box-sizing: border-box;
    border: none;
    font-size: 0.8rem;
    font-weight: 800;
    margin-top: .5rem;
    cursor: pointer;
    transition: all ease 0.2s;
}

.main {
    display: flex;
    justify-content: space-evenly;
    height: 25%;
    align-content: space-around;
    align-items: center;
}

.select {
    display: flex;
    width: 50%;
}
</style>
