<template>
  <div class="home">
    <Header :height="150" />
    <section class="topTextContainer">
      <h2 class="title">Register</h2>
      <div class="favoriteIconContainer" @click="openFavoritesModal">
        <span class="material-icons-round iconHeader">star</span>
        <p class="text">Favorites</p>
      </div>
    </section>

    <CarouselFavorites />

    <div class="inputContainer">
      <Input class="customDate" icon="calendar_today" placeholder="Date" type="customDate" v-model="data.worked_date" />
      <div class="selectableContainer" @click="() => navigation('projects')">
        <Input v-model="store.getters.searchProjects.name" type="withIcon" placeholder="Choose Project" icon="all_inbox"
          readonly />
      </div>
      <div class="selectableContainer" @click="() => navigation('activities')">
        <Input v-model="store.getters.searchActivities.name" type="withIcon" placeholder="Activity" icon="trending_up" />
      </div>
      <div class="selectableContainer" @click="() => navigation('label')">
        <Input v-model="store.getters.searchLabel.name" type="withIcon" placeholder="Label" icon="label_important" />
      </div>
      <div class="selectableContainer" @click="() => navigation('categories')">
        <Input v-model="store.getters.searchCategories.name" type="withIcon" placeholder="Category" icon="workspaces" />
      </div>
      <div class="selectableContainer" @click="() => navigation('disciplines')">
        <Input v-model="store.getters.searchDisciplines.name" type="withIcon" placeholder="Discipline"
          icon="view_in_ar" />
      </div>
      <Input type="withIcon" v-model="data.description" placeholder="Description" icon="menu_book" />
      <Input type="customTime" v-model="data.worked_hour" placeholder="Inform time spent in job" icon="timer" />

      <label class="saveFavoriteContainer">
        <input class="input-save-favorite" type="checkbox" :value="store.getters.toggle" :checked="store.getters.toggle"
          @click="openFavoritesNicknameModal" />
        <span class="checkmark"></span>
        <span class="material-icons-round iconHeader">check_circle_outline</span>
        <p class="saveFavorite">Save as favorite {{ store.getters.registerFavoriteNickname ? 'with nickname: ' +
          store.getters.registerFavoriteNickname : '' }} </p>
      </label>

      <Button type="edit" text="Save" :click="create" />
    </div>
    <div class="lastEntries">
      <p class="lastEntries" @click="goToEntries">Previous entries</p>
    </div>
    <div class="btnContainer">
      <Button type="modalUser" text="Continue" :click="() => { }" />
    </div>
    <div class="logoutContainer">
      <div v-if="role != 2">
        <span class="material-icons-round iconHeader" @click="home">home</span>
        <p class="text">Select</p>
      </div>

      <div>
        <span class="material-icons-round iconHeader" @click="logout">logout</span>
        <p class="text">Logout</p>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Button from '../components/Elements/Button.vue';
import Input from '../components/Elements/Input.vue';
import { inject, onMounted, onUnmounted, ref } from "vue";
import { isEmpty, /*isNumber*/ } from '../services/validate';
import { POST, GET, PATCH } from "../services/api";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import CarouselFavorites from '@/components/CarouselFavorites.vue';


export default {
  name: 'Home',
  components: {
    Header,
    Button,
    Input,
    CarouselFavorites
  },
  setup() {
    const loader = inject("loading");
    const router = useRouter();

    const user = JSON.parse(localStorage.getItem("info_dynamik_webapp"));


    const data = ref({
      worked_date: "",
      worked_hour: "",
      description: "",
      projects_id: "",
      activities_id: "",
      label_id: "",
      categories_id: "",
      disciplines_id: "",
      users_id: user.sub,
    });
    const toggle = ref(false);

    const store = useStore();
    const modal = inject("modalSuccess");
    const modalError = inject("modalError");

    //Favorites 
    const modalFavorites = inject("modalFavorites");
    const modalFavoritesNickname = inject("modalFavoritesNickname");
    const favoriteNickname = ref();

    const role = ref('')

    onMounted(async () => {
      const aux = localStorage.getItem('setRole')

      role.value = JSON.parse(aux)

      if (role.value === 5) {
        router.push('/score_client')
      }
      else if (role.value === 2) {
        router.push('/')
      }

      const date = new Date();
      date.setDate(date.getDate());
      data.value.worked_date = store.getters.registerWorkedDate;
      data.value.worked_hour = store.getters.registerWorkedHour;
      data.value.description = store.getters.registerDescription;


      const favorite = await GET(`users/favorite/${user.sub}`);
      store.commit("filterDate", '');


      if (favorite) {
        localStorage.setItem("favorites", JSON.stringify(favorite));
        store.commit("setFavorite", favorite);
      } else {
        localStorage.setItem("favorites", []);
        store.commit("setFavorite", []);
      }


    })

    const navigation = (path) => {
      router.push(`/search/${path}`);
    }

    const openSuccessModal = async () => {
      loader.open();
      modal.open("Success!");
      loader.close();
    }

    const openErrorModal = async (message) => {
      loader.open();
      modalError.openError(message);
      loader.close();
    }

    const openFavoritesModal = async () => {
      loader.open();
      modalFavorites.openFavorites();
      loader.close();
    }


    const openFavoritesNicknameModal = async () => {
      if (store.getters.toggle) {
        store.commit("setRegisterFavoriteNickname", "");
        store.commit("toggle", false);

      } else {
        loader.open();
        modalFavoritesNickname.openFavoritesNickname();
        loader.close();
      }
    }

    const goToEntries = async () => {
      loader.open();

      store.commit("setSearchProjects", "");
      store.commit("setSearchActivities", "");
      store.commit("setSearchLabel", "");
      store.commit("setSearchCategories", "");
      store.commit("setSearchDisciplines", "");
      store.commit("setRegisterDescription", "");
      store.commit("setRegisterWorkedHour", "");
      store.commit("setRegisterWorkedDate", "");
      data.value.description = "";
      data.value.worked_hour = "";

      router.push('/entries');
      loader.close();
    }

    const logout = () => {
      loader.open();
      localStorage.removeItem('t_dynamik_webapp');
      localStorage.removeItem("info_dynamik_webapp");

      store.commit("setSearchProjects", "");
      store.commit("setSearchActivities", "");
      store.commit("setSearchLabel", "");
      store.commit("setSearchCategories", "");
      store.commit("setSearchDisciplines", "");
      store.commit("setRegisterDescription", "");
      store.commit("setRegisterWorkedHour", "");
      store.commit("setRegisterWorkedDate", "");
      data.value.description = "";
      data.value.worked_hour = "";

      router.push('/login');
      loader.close();
    }

    const home = () => {
      router.push('/home');
    }

    onUnmounted(async () => {
      store.commit("setRegisterDescription", data.value.description);
      store.commit("setRegisterWorkedHour", data.value.worked_hour);
      store.commit("setRegisterWorkedDate", data.value.worked_date);
    })

    const create = async () => {
      loader.open();
      const obj = {
        worked_date: data.value.worked_date,
        worked_hour: data.value.worked_hour,
        projects_id: store.getters.searchProjects.id,
        activities_id: store.getters.searchActivities.id,
        label_id: store.getters.searchLabel.id,
        categories_id: store.getters.searchCategories.id,
        disciplines_id: store.getters.searchDisciplines.id,
        users_id: user.sub,
      }
      if (data.value.worked_hour != null) {
        let minutes = obj.worked_hour;
        if (minutes == null || minutes == "") {
          obj.worked_hour = "";
        } else if (minutes.length == 1) {
          let newMinutes = "0" + minutes + ":00";
          minutes = newMinutes;
        } else if (minutes.length == 3) {
          let newMinutes = minutes + "00";
          minutes = newMinutes;
        } else if (minutes.length == 4) {
          let newMinutes = minutes + "0";
          minutes = newMinutes;
        }

        minutes = minutes.split(":");
        let correctMinutes = minutes[1];
        let correctHours = minutes[0]
        if (correctMinutes > 59) {
          correctMinutes = 59;
        }
        if (correctHours > 23) {
          correctHours = 23;
        }
        const correctTime = correctHours + ":" + correctMinutes;
        obj.worked_hour = correctTime;
      }

      const objCopy = obj;


      const validate = isEmpty(objCopy);
      //const validateHours = isNumber(obj.worked_hour);
      if (validate /*&& !validateHours*/) {
        validate && openErrorModal("Fill all fields to continue");
      } else {
        try {
          const objCopy = { ...obj, description: data.value.description };
          const response = await POST("hours_record", objCopy);
          let favoriteExists = false;
          //Record was registered successfully!
          if (response && response.record && response.record.success) {
            openSuccessModal(response.record.msg);
          } else {
            openErrorModal(response.record.msg)
          }
          //openSuccessModal();
          if (store.getters.toggle) {
            const favs = localStorage.getItem("favorites") ? JSON.parse(localStorage.getItem("favorites")) : [];
            const fav = {
              id: favs.length,
              nickname: store.getters.registerFavoriteNickname,
              projects: store.getters.searchProjects,
              activities: store.getters.searchActivities,
              label: store.getters.searchLabel,
              categories: store.getters.searchCategories,
              disciplines: store.getters.searchDisciplines,
            }
            for (let i = 0; i < favs.length; i++) {
              const element = favs[i];
              if (element.projects.id == fav.projects.id &&
                element.activities.id == fav.activities.id &&
                element.label.id == fav.label.id &&
                element.categories.id == fav.categories.id &&
                element.disciplines.id == fav.disciplines.id) {
                favoriteExists = true;
              }
            }
            if (!favoriteExists) {
              favs.push(fav);
              store.commit("setFavorite", favs);

              localStorage.setItem("favorites", JSON.stringify(favs));

              const body = {
                favorites: JSON.stringify(favs)
              }

              try {
                await PATCH(`users/favorite/${user.sub}`, body);
              } catch (error) {
                modalError.openError('Something went wrong');
                console.log(error);
              }
            }
          }
          store.commit("setSearchProjects", "");
          store.commit("setSearchActivities", "");
          store.commit("setSearchLabel", "");
          store.commit("setSearchCategories", "");
          store.commit("setSearchDisciplines", "");
          store.commit("setRegisterWorkedHour", "");
          store.commit("setRegisterWorkedDate", "");
          store.commit("setRegisterDescription", "");
          data.value.description = "";
          data.value.worked_hour = "";
          store.commit("toggle", false);
          store.commit("setRegisterFavoriteNickname", "");

          loader.close();
        } catch (e) {
          loader.close();
          if (e.includes("Token") || e.includes("t_dynamik_webapp") || e == "Unauthorized") {
            localStorage.removeItem("t_dynamik_webapp");
            localStorage.removeItem("info_dynamik_webapp");
            openErrorModal("Unauthorized");
            router.push({ path: '/login' });
          } else {
            openErrorModal("Something went wrong");
          }
        }
      }
    }

    return {
      navigation,
      openSuccessModal,
      openErrorModal,
      openFavoritesModal,
      openFavoritesNicknameModal,
      goToEntries,
      logout,
      create,
      data,
      store,
      toggle,
      home,
      role,
      favoriteNickname
    }
  },
  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    })
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400;500;600;700&display=swap');

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body {
  margin: 0;
}

.home {
  background-color: #E5E5E5;
  height: 100vh;
  overflow: auto;
}

.btnContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.inputContainer {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
}

.selectableContainer {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.favoriteIconContainer {
  color: #8f969e;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.logoutContainer {
  color: #8f969e;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: top;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto;
}

.saveFavoriteContainer {
  color: #8f969e;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.saveFavoriteContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 9px;
  left: 4px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #8f969e;
}

.saveFavoriteContainer:hover input~.checkmark {
  background-color: #bebebe;
}

.saveFavoriteContainer input:checked~.checkmark {
  background-color: #2f90b0;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.saveFavoriteContainer input:checked~.checkmark:after {
  display: block;
}

.saveFavoriteContainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.iconHeader {
  font-size: 25px;
  padding: .6rem .3rem 0 .3rem;
}

.topTextContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}

.label {
  color: #fff;
  font-size: 14px;
  margin-bottom: .25rem;
}

.saveFavorite {
  color: #8f969e;
  font-weight: 200;
  font-size: .875rem;
  cursor: pointer;
}

.favoriteButton {
  color: #8f969e;
  font-weight: 200;
  font-size: 1rem;
  cursor: pointer;
}

.lastEntries {
  color: #2f90b0;
  font-weight: 600;
  font-size: .875rem;
  cursor: pointer;
  text-align: center;
  margin: auto;
  width: 60%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>