<template>
    <div v-if="store.getters.modalFavoritesNickname.display">
        <div class="modal-mask" @click="closeModal"></div>
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="body">
                    <slot name="body">
                        <h1 class="title-modal">Type here a nickname for your favorite</h1>
                        <Input type="filterProject" placeholder="favorite nickname" icon="star" v-model="nicknameFavorite" />
                        
                        <Button type="modalFavorites" text="Save" :click="saveFavoriteNickname" />
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { inject, ref } from "vue";
import { useStore } from 'vuex';
import Button from './Button.vue';
import Input from './Input.vue';
import { isEmpty } from '@/services/validate';

export default {
    name: "ModalFavoritesNickname",
    components: {
        Button,
        Input
    },
    setup() {
        const store = useStore();
        const modalError = inject("modalError");
        const loader = inject("loading");

        const nicknameFavorite = ref();

        const closeModal = () => store.commit("setModalFavoritesNickname", false);

        const openErrorModal = async (message) => {
            loader.open();
            modalError.openError(message);
            loader.close();
        }

        const saveFavoriteNickname = () => {

            const obj = { nickname: nicknameFavorite.value };
            const validate = isEmpty(obj);

            if(validate) {
                store.commit('setRegisterFavoriteNickname', '');
                nicknameFavorite.value = '';
                store.commit("toggle", true);
                validate && openErrorModal('Give your favorite a nickname');
                closeModal();
            } else {
                const favs = store.getters.favoriteGetter;
                const isNicknameRegisted = favs.findIndex(fav => fav.nickname === nicknameFavorite.value);

                if(isNicknameRegisted === -1){
                    store.commit("setRegisterFavoriteNickname", nicknameFavorite);
                    store.commit("toggle", !store.getters.toggle); 
                    closeModal();
                } else {
                    store.commit('setRegisterFavoriteNickname', '');
                    nicknameFavorite.value = '';
                    store.commit("toggle", false);
                    openErrorModal('Nickname already exists!');
                    closeModal();
                }
                
            }            
        }

        return { closeModal, store, nicknameFavorite, saveFavoriteNickname, openErrorModal }
    },
}
</script>

<style scoped>
    .modal-enter .modal-container,
    .modal-leave-active {
        opacity: 0;
    }

    .body {
        max-height: calc(100vh - 150px);
        /* overflow-y: auto; */
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-mask{
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-container{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        width: 80vw;
        max-height: 83vh;
        padding: 2em 1rem;
        margin: auto;
        border-radius: 10px;
        z-index: 99999;
        overflow: auto;
    }
    
    .title-modal{
        color: #272727;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 1em;
    }

    .subtitle-modal{
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        font-size: 15px;
        margin-bottom: 2rem;
    }


    @media screen and (min-width: 690px) {

        .modal-container{
            width: 515px;
            padding: 3rem 2.5em;
        }
    }

    @media screen and (min-width: 1025px) {

        .modal-container{
            height: 93vh;
            max-height: none;
        }
    }


</style>
