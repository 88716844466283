<template>
    <div v-if="store.getters.modalEditFavoritesNickname.display">
        <div class="modal-mask" @click="closeModal"></div>
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="body">
                    <slot name="body">
                        <h1 class="title-modal">Edit your favorite nickname</h1>
                        <Input type="filterProject" placeholder="favorite nickname" icon="star" v-model="nicknameFavoriteEdited" />
                        
                        <Button type="modalFavorites" text="Save" :click="saveNewFavoriteNickname" />
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { inject, ref } from "vue";
import { useStore } from 'vuex';
import Button from './Button.vue';
import Input from './Input.vue';
import { isEmpty } from '@/services/validate';
import { PATCH } from "../../services/api";

export default {
    name: "ModalEditNicknameFavorite",
    components: {
        Button,
        Input
    },
    setup() {
        const store = useStore();
        const modalError = inject("modalError");
        const modalSuccess = inject("modalSuccess");
        const loader = inject("loading");
        const modalFavorites = inject("modalFavorites");

        const nicknameFavoriteEdited = ref();
        const user = JSON.parse(localStorage.getItem("info_dynamik_webapp"));

        const closeModal = () => store.commit("setModalEditFavoritesNickname", false);

        const openErrorModal = async (message) => {
            loader.open();
            modalError.openError(message);
            loader.close();
        }

        const openSuccessModal = async () => {
            loader.open();
            modalSuccess.open("Nickname succesfully changed!");
            loader.close();
        }

        const saveNewFavoriteNickname = async () => {
            const obj = { nickname: nicknameFavoriteEdited.value };
            const validate = isEmpty(obj);

            if(validate) {
                nicknameFavoriteEdited.value = '';
                openErrorModal("Nickname cannot be empty!");
                modalFavorites.closeFavorites();
                closeModal();
            } else {
                const id = store.getters.modalEditFavoritesNickname.id;

                const favs = JSON.parse(localStorage.getItem("favorites"));
                const index = favs.findIndex(fav => fav.id === id);
                const isNicknameRegisted = favs.findIndex(fav => fav.nickname === nicknameFavoriteEdited.value);
                
                if(isNicknameRegisted === -1){
                    if(index != -1) {
                        favs[index].nickname = nicknameFavoriteEdited.value;
                        store.commit("setFavorite", favs);
                        localStorage.setItem("favorites", JSON.stringify(favs));
                        nicknameFavoriteEdited.value = '';

                        const body = {
                            favorites: localStorage.getItem("favorites")
                        }

                        try {
                            await PATCH(`users/favorite/${user.sub}`, body);
                        } catch (error) {
                            console.log(error);
                        }

                        openSuccessModal("Nickname sucessfully changed!");
                        modalFavorites.closeFavorites();
                        closeModal();
                    }
                } else {
                    nicknameFavoriteEdited.value = '';
                    openErrorModal("Nickname already exists!");
                    modalFavorites.closeFavorites();
                    closeModal();
                } 
            }            
        }

        return { closeModal, store, nicknameFavoriteEdited, saveNewFavoriteNickname, openErrorModal, openSuccessModal }
    },
}
</script>

<style scoped>
    .modal-enter .modal-container,
    .modal-leave-active {
        opacity: 0;
    }

    .body {
        max-height: calc(100vh - 150px);
        /* overflow-y: auto; */
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    .modal-mask{
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: table;
        transition: opacity 0.3s ease;
    }

    .modal-container{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        width: 80vw;
        max-height: 83vh;
        padding: 2em 1rem;
        margin: auto;
        border-radius: 10px;
        z-index: 99999;
        overflow: auto;
    }
    
    .title-modal{
        color: #272727;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 1em;
    }

    .subtitle-modal{
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        font-size: 15px;
        margin-bottom: 2rem;
    }


    @media screen and (min-width: 690px) {

        .modal-container{
            width: 515px;
            padding: 3rem 2.5em;
        }
    }

    @media screen and (min-width: 1025px) {

        .modal-container{
            height: 93vh;
            max-height: none;
        }
    }


</style>
