<template>
    <div class="loginPage">
        <Header :height="250" />
        <div class="inputContainer">
            <h2 class="title">Authorization</h2>
            <Input type="default" v-model="user.email" placeholder="Email" label="Email" />
            <Input type="password" v-model="user.password" placeholder="Password" label="Password" />
            <p class="doubts" @click="openHowToAccessModal">How to access</p>
            <Button type="login" text="Login" :click="login" />
        </div>
    </div>
</template>

<script>
import Header from '../components/Header.vue';
import Input from '../components/Elements/Input.vue';
import Button from '../components/Elements/Button.vue';
import { inject, ref } from "vue";
import { useRouter } from 'vue-router';
import { isEmpty, isEmail } from '../services/validate';
import { decodeJwt } from '../services/util';
import { LOGIN, instance } from '@/services/api';
import store from '@/store';

export default {
    name: "Login",
    components: {
        Header,
        Input,
        Button
    },
    setup() {
        const user = ref({
            email: "",
            password: "",
        });

        //const modalSuccess = inject("modalSuccess");
        const modalError = inject("modalError");
        const modalHowToAccess = inject("modalHowToAccess");

        const router = useRouter();
        const loader = inject("loading");

        const openErrorModal = async () => {
            loader.open();
            //store.commit("setModalSuccess", true);
            modalError.openError("Something went wrong");
            loader.close();
        }

        const openHowToAccessModal = async () => {
            loader.open();
            //store.commit("setModalSuccess", true);
            modalHowToAccess.openHowToAccess();
            loader.close();
        }

        const login = async () => {
            const validate = isEmpty(user.value);
            const emailValidate = isEmail(user.value.email);

            if (!validate && emailValidate) {
                loader.open();
                try {
                    const token = await LOGIN("/auth/login", user.value);

                    localStorage.setItem("t_dynamik_webapp", token);
                        instance.defaults.headers.Authorization = (token) ? `Bearer ${token}` : '';

                        const decodedJWT = decodeJwt(token)

                        localStorage.setItem("info_dynamik_webapp", JSON.stringify(decodedJWT));

                        store.commit('setRole', decodedJWT.role)

                        localStorage.setItem('setRole', JSON.stringify(decodedJWT.role))

                        //modalSuccess.open("Login successfull!");



                        switch (store.getters.role) {
                            case 2:
                                router.push({ path: "/" });
                                break
                            case 5:
                                router.push({ path: "/score_client" });
                                break
                            default:
                                router.push({ path: "/home" });
                        }


                    loader.close();
                } catch (e) {
                    
                    modalError.openError(e);
                    console.log(e);
                    loader.close();
                }
            } else {
                !emailValidate && modalError.openError("Please insert a valid email");
                validate && modalError.openError("Fill all fields to continue");
            }
        }

        return { user, login, openErrorModal, openHowToAccessModal };
    },
}
</script>

<style scoped>
.loginPage {
    background-color: #E5E5E5;
    height: 100vh;
    overflow: auto;
}

.inputContainer {
    width: 80%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin: 0 auto;
}

.title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #333333;
}

.doubts {
    color: #2f90b0;
    font-weight: 600;
    font-size: .875rem;
    cursor: pointer;
}
</style>
