import { createRouter, createWebHistory } from 'vue-router'
import 'material-icons/iconfont/material-icons.css';
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Search from '../views/Searchs/Search.vue'
import PreviousEntries from '../views/PreviousEntries.vue'
import Edit from '../views/Edit.vue'
import Select from '../views/Select.vue'
import Score_Client from '../views/Scores/Score_Client.vue'
import Score_Adm from '../views/Scores/Score_Adm.vue'

import SearchScore from '../views/Searchs/SearchScore.vue'
//import store from '@/store';


const routes = [
  {path: '/login', name: 'Login', component: Login},
  {path: '/search/:id', name: 'Search', component: Search},
  {path: '/search/score/:id', name: 'SearchScore', component: SearchScore},
  {path: '/entries', name: 'My Entries', component: PreviousEntries},
  {path: '/edit/:id', name: 'Edit', component: Edit},
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/home',
    name: 'Select',
    component: Select
  },
  {
    path: '/score_client',
    name: 'Score_Client',
    component: Score_Client
  },
  {
    path: '/score_adm',
    name: 'Score_Adm',
    component: Score_Adm
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router
