import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import loading from './services/loading'
//import loading from './services/loading'
import Maska from 'maska'
import modal from './services/modal'
import 'material-icons/iconfont/material-icons.css'

createApp(App)
.use(store)
.use(router)
.use(Maska)
.provide('modalSuccess', modal)
.provide('modalError', modal)
.provide('modalFavorites', modal)
.provide('modalFavoritesNickname', modal)
.provide('ModalEditFavoritesNickname', modal)
.provide('modalHowToAccess', modal)
.provide('modalConfirmation', modal)
.provide('modalConfirmationEntries', modal)
.provide('modalFilterEntries', modal)
.provide('loading', loading)
.mount('#app')