carouselFavorites
<template>
    <div class="carouselContainer">
        <swiper 
            :modules="modules"
            :slides-per-view="3"
            :space-between="15"
            :slidesOffsetAfter="50"  
            :allowTouchMove="true" 
            :slidesOffsetBefore="0"         
        >
            <template :key="index" v-for="(item, index) in store.getters.favoriteGetter">
                <swiper-slide>
                    <button class="btn" @click="selectFavorite(item.id)" style="font-size: 0.9em;">
                     {{ item.nickname.toUpperCase() }}
                    </button>
                </swiper-slide>
            </template>
        </swiper>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    name: 'CarouselFavorite',
    components: {
        Swiper,
        SwiperSlide,
    },
    setup(){
        const store = useStore();

        const selectFavorite = (id) => {
            const selectedFavorite = store.getters.favoriteGetter.find(item => item.id === id);
            const selectedCurrentFavorite = JSON.parse(JSON.stringify(selectedFavorite));
         
            
            store.commit("setSearchProjects", selectedCurrentFavorite.projects);
            store.commit("setSearchActivities", selectedCurrentFavorite.activities);
            store.commit("setSearchLabel", selectedCurrentFavorite.label);
            store.commit("setSearchCategories", selectedCurrentFavorite.categories);
            store.commit("setSearchDisciplines", selectedCurrentFavorite.disciplines);
        }

    return {  
        store,
        selectFavorite,
        modules: [Navigation, Pagination, Scrollbar, A11y],
    };
},
    
}
</script>

<style>
    .carouselContainer {
        width: 100%;
    }
    .swiper {
        max-width: 80%;
        margin: 1rem auto;

        justify-content: flex-start;
        text-align: left;
    }
    .swiper-slide {
        margin: 0.5rem 0;
    }

    .swiper-slide button {
        width: 100%;
        padding: 0.5rem 1.5rem;
        border-radius: 6px;
        border: 0;
        background: #2f90b0;
        color: #fff;
        font-weight: 500;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25); 
        cursor: pointer;
        transition: all ease 0.2s;       
    }

    .swiper-slide button:hover {
        background: #2f90b0;        
    }

    .swiper-button-next:after, 
    .swiper-button-prev:after {
        color: #8f969e;
        font-size: 1rem;
        font-weight: bold;
        
    }

    .swiper-button-prev {
        left: 0;
        color: #8f969e;
    }

    .swiper-button-next {
        right: 0;
        color: #8f969e;
    }

    .btn{
            overflow-wrap: break-word;
            word-wrap: break-word;
            hyphens: auto;
            padding-top: 9px;
            padding-bottom: 9px;
            
            padding-right: 4px;
            padding-left: 4px;
            text-align: center;
            font-weight: bold;
            font-size: 1.15em;
        }


    @media screen and (max-width: 690px) {

        .swiper-slide button {
            width: 100%;
            padding: 0.5rem;
            border-radius: 6px;
            border: 0;
            background: #2f90b0;
            color: #fff;
            font-size: 0.5rem;
            font-weight: 500;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25); 
            cursor: pointer;
            transition: all ease 0.2s;       
        }
        .btn{
            overflow-wrap: break-word;
            word-wrap: break-word;
            hyphens: auto;
            padding-top: 9px;
            padding-bottom: 9px;
            font-weight: bold;
            padding-right: 4px;
            padding-left: 4px;
            
            text-align: center;
            font-weight: bold;
            font-size: 2em;

            color: blue;

        }

    }
</style>