<template>
    <div class="main">
        <Header :height="150" />
        <section class="ContainerHeader">
            <div class="headerh3">
                <h2 class="title">Scoring</h2>
            </div>
        </section>
        <div class="headerspan">
            <span>
                Thank you {{ user.name }}.
                To evaluate this project, select the name of the project in the project field.
                After that, select from input 1 - 10 and click submit button
            </span>
        </div>
        <div class="inputContainer">
            <div class="selectableContainer" @click="() => navigation('projects')">
                <Input v-model="store.getters.searchProjectsScore.name" type="withIcon" placeholder="Choose Project"
                    icon="all_inbox" readonly />
            </div>
            <Input type="number" placeholder="Select Score" icon="speed" readonly v-model="score.score" />

        </div>
        <br>
        <div class="buttonContainer">
            <Button type="edit" text="Submit" :click="Submit" />
        </div>
        <div class="logoutContainer2">

            <div>
                <span class="material-icons-round iconHeader" @click="logout">logout</span>
                <p class="text">Leave</p>
            </div>
        </div>
    </div>
</template>

<script>

import Header from '../../components/Header.vue';
import Input from '../../components/Elements/Input.vue';
import Button from '../../components/Elements/Button.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { POST } from "../../services/api";
import { ref, inject, onMounted } from "vue";

export default {
    name: 'Score_Client',
    components: {
        Header,
        Button,
        Input,
    },

    setup() {

        const store = useStore();
        const router = useRouter();

        const user = ref({})

        const score = ref({
            score: "",
            projectId: store.getters.searchProjectsScore.id,
            scorekeeper: "guest"
        })


        const loader = inject("loading");

        const modal = inject("modalSuccess");
        const modalError = inject("modalError");



        const openSuccessModal = async (value) => {
            loader.open();
            modal.open(`${value}`);
            loader.close();
        }

        const openErrorModal = async (value) => {
            loader.open();
            if (value !== undefined) {
                modalError.openError(value);
            } else {
                modalError.openError("Something went wrong");
            }
            loader.close();
        }



        const navigation = (path) => {
            router.push(`/search/score/${path}`);
        }

        const Submit = async () => {
            if (score.value.score === "" || score.value.projectId === "") {
                openErrorModal()
            } else {
                loader.open();
                try {

                    const response = await POST('/score/guest', score.value)

                    
                    if(!response.includes('already'))
                    {
                        openSuccessModal(response);
                    }else{
                        openErrorModal(response)
                    }

                
                    loader.close();
                } catch (e) {
                    loader.close();
                    openErrorModal();
                }
            }

        }


        onMounted(() => {
            const ls = localStorage.getItem('info_dynamik_webapp')


            user.value = JSON.parse(ls)


        })

        const logout = () => {
            loader.open();
            localStorage.removeItem('t_dynamik_webapp');
            localStorage.removeItem("info_dynamik_webapp");

            router.push('/login');
            loader.close();
        }


        return {
            store,
            navigation,
            score,
            Submit,
            user,
            logout
        }
    }
}
</script>

<style>

.main{
    background-color: #E5E5E5;
}
.headerspan {
    display: flex;
    margin-left: 5vw;
}

.headerh3 {
    display: flex;
    margin-left: 5vw;
}

.inputContainer2 {
    height: 20vh;
}

.buttonContainer {
    height: 40vh;
    display: flex;
    align-items: center;
}

.logoutContainer2 {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-right: 5vw;
}


.infoManagerCoodinator{
    margin-top:10px;
    margin-bottom:10px;
    display:flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    gap:8px
}


</style>