<template>
    <div class="bg">
        <section class="topSection">
            <div class="circle">
                <img src="../assets/logo.png" alt="Logo Prefablogic" class="image" />
            </div>
        </section>
        <Button type="floatingButton" id="btn_date" icon="date_range" :click="() => openFilterEntriesModal()" />
        <div class="bottomSection">
            <div class="topText">
                <span class="material-icons-round iconHeader" @click="backToMain">arrow_back_ios_new</span>
                <h1 class="title">Previous entries</h1>
            </div>

            <div class="carousel">
                <span class="material-icons-round iconBody  arrowLeft" @click="getDaysback()">arrow_back_ios</span>
                <CarouselDay :week="week" v-if="week.length == 5" />
                <span class="material-icons-round iconBody arrowRight" @click="getDaysforward()">arrow_forward_ios</span>

            </div>
            <container class="entries" v-if="data">
                <template v-for="(item, index) in data" :key="index">
                    <div class="entry" v-if="item.id">
                        <div class="firstColumn">
                            <p class="mainText"> {{ item.worked_hour.split(":")[0] + ":" + item.worked_hour.split(":")[1]
                            }}h
                            </p>
                            <p class="subText"> {{ toDateUS(item.worked_date) }}</p>
                        </div>
                        <div class="secondColumn">
                            <p class="mainText"> {{ item.projects_id.name }} </p>
                            <p class="subText"> {{ item.description ? item.description : '-/-' }}</p>
                        </div>
                        <div class="thirdColumn">
                            <span class="material-icons-round entryIcon"
                                @click="() => openConfirmationModal(item.id)">delete</span>
                            <span class="material-icons-round entryIcon" @click="goToEdit(item.id)">edit</span>
                        </div>
                    </div>
                    <hr class="solid" v-if="item.id">

                    <div v-else class="entry">
                        <h4 class="secondColumn">No Entries Found in {{ mydate(item) }}</h4>
                    </div>
                </template>
                <section v-show="data.length == 0" class="message">
                    <h4>Please pick up a date to see relative inputs</h4>
                </section>
            </container>
        </div>
    </div>
</template>

<script>

import { useRouter } from 'vue-router';
import { inject, onMounted, ref, watch } from "vue";
import { toDateUS } from "../services/formatter";
import { GET } from "../services/api";
import { useStore } from 'vuex';

import moment from "moment";
import { dateToBackend } from '@/services/util';


import CarouselDay from '../components/CarouselDay.vue';
import Button from '../components/Elements/Button.vue';


export default {
    name: 'PreviousEntries',
    components: {
        Button,
        CarouselDay
    },

    setup() {
        const router = useRouter();
        const store = useStore();
        const loader = inject("loading");
        const modalError = inject("modalError");
        const modalConfirmationEntries = inject("modalConfirmationEntries");
        const modalFilterEntries = inject("modalFilterEntries");
        let initialDate = ref("");
        let finalDate = ref("");
        const data = ref([]);
        const week = ref([])
        const user = ref()
        const selected = ref(false)

        const aux_data = ref([]);




        const openConfirmationModal = async (id) => {
            loader.open();
            modalConfirmationEntries.openConfirmationEntries("Title", "Message", "Confirm Message", id);
            loader.close();
        }

        const openFilterEntriesModal = async () => {
            loader.open();
            modalFilterEntries.openFilterEntries("Title", "Message", "Confirm Message");
            loader.close();
        }


        const getDaysback = async () => {
            loader.open();
            try {
                selected.value = false
                const date = new Date(week.value[0].date)
                const date_aux = dateToBackend(date.toLocaleDateString('en-US'))


                store.commit("filterDate", date_aux);
            } catch (e) {

                loader.close();
            }
            loader.close();

        }

        const getDaysforward = async () => {
            loader.open();
            try {
                selected.value = false
                const date = new Date(week.value[4].date)
                date.setDate(date.getDate() + 6);
                const date_aux = dateToBackend(date.toLocaleDateString('en-US'))


                store.commit("filterDate", date_aux);
            } catch (error) {
                loader.close();
            }
            loader.close();
        }


        const backToMain = async () => {
            loader.open();
            week.value = [];

            const aux = JSON.parse(localStorage.getItem('setRole'))

            if (aux != 2) {
                router.push("/home");
            } else {
                router.push("/");
            }



            loader.close();
        }

        const totalHours = async (hoursArray) => {
            const total = ref(0)
            const sum = hoursArray.reduce((acc, time) => acc.add(moment.duration(time)), moment.duration());
            total.value = [Math.floor(sum.asHours()), sum.minutes()].join(':');
            total.value = total.value.split(":");

            let correctMinutes = total.value[1];
            let correctHours = total.value[0];

            if (correctMinutes.length == 1) {
                correctMinutes = "0" + correctMinutes;
            }

            if (correctHours.length == 1) {
                correctHours = "0" + correctHours;
            }

            total.value = correctHours + ":" + correctMinutes;

            return total.value;
        }

        const loadEntries = async () => {
            loader.open();

            const user = JSON.parse(localStorage.getItem("info_dynamik_webapp"));

            week.value = []

            data.value = []
            selected.value = false

            aux_data.value = []
            try {


                const aux_week = await GET(`hours_record/get_by_user_sorted/${user.sub}/x/${store.getters.filterDate}`)

  



                aux_data.value = aux_week


                let i = 0;
                const weekday = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];


                for (const day of aux_week) {

                    const hour = day.map(element => {
                        if (element.worked_hour) {
                            return element.worked_hour
                        } else {
                            return '00:00:00'
                        }
                    })

                    const date = day.map(element => {

                        if (element.worked_date) {
                            const aux = element.worked_date.split("T")
                            return aux[0]
                        } else {
                            return element
                        }
                    })
                    const TotalHours = await totalHours(hour)

                    const h = TotalHours.split(':')

   

                    const d = new Date(date[0])
   


                    let dia = d.getDay() + 1


                    if (dia == 7) {
                        dia = 0
                    }

                    week.value.push({
                        id: i,
                        name: weekday[dia],
                        date: date[0],
                        hour: TotalHours,
                        color: parseInt(h[0])
                    })
                    i++;

                }
                loader.close();
            } catch (e) {
                console.log(e);
                loader.close();
                if (e.includes("Token") || e.includes("t_dynamik_webapp") || e == "Unauthorized") {
                    localStorage.removeItem("t_dynamik_webapp");
                    localStorage.removeItem("info_dynamik_webapp");
                    modalError.openError("Unauthorized");
                    router.push({ path: '/login' });
                }
            }
        }

        const goToEdit = async (record_id) => {
            loader.open();
            const record = await GET(`hours_record/${record_id}`);

            store.commit("setEditProjects", record.projects_id);
            store.commit("setEditActivities", record.activities_id);
            store.commit("setEditLabel", record.label_id);
            store.commit("setEditCategories", record.categories_id);
            store.commit("setEditDisciplines", record.disciplines_id);
            store.commit("setEditDescription", record.description);
            store.commit("setEditWorkedHour", record.worked_hour);
            store.commit("setEditWorkedDate", record.worked_date);

            router.push(`/edit/${record_id}`);
            loader.close();
        }


        onMounted(async () => {

            const aux = localStorage.getItem('setRole')

           const role = JSON.parse(aux)

            if (role === 5) {
                router.push('/score_client')
            }

            loader.open();
            user.value = JSON.parse(localStorage.getItem("info_dynamik_webapp"));

            try {
                let date = new Date();
                let finalDateArray = date.toLocaleDateString('en-US');
                finalDate.value = dateToBackend(finalDateArray);
                store.commit("filterDate", finalDate.value);
                loader.close();
            } catch (e) {
                console.log(e);
                loader.close();
            }
        })



        watch(() => store.getters.day, () => {
            selected.value = true
            data.value = aux_data.value[store.getters.day]
        })

        watch(() => store.getters.filterDate, async () => {
            selected.value = false

            await loadEntries();

        })


        const mydate = (item) => {
            const d = item;
            const aux = d.split('-')
            return aux[1] + '/' + aux[2] + '/' + aux[0]

        }



        return {
            backToMain,
            loadEntries,
            goToEdit,
            data,
            toDateUS,
            openConfirmationModal,
            openFilterEntriesModal,
            totalHours,
            initialDate,
            finalDate,
            week,
            getDaysforward,
            getDaysback,
            mydate,
            selected,

        }
    },
}
</script>

<style scoped>
.bg {
    background-color: #2f90b0;
    ;

}

.topSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
}

.iconHeader {
    margin: 0 0.3em 0 0;
}

.entryIcon {
    margin: 0 0 0 1rem;
    color: #2f90b0;
    ;
}

.iconBody {
    margin: 0 0.3em 0 0;
}

.entry {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
}

.title {
    margin: .8em 0 1em 0;
    padding: .5em 0 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
}

.image {
    width: auto;
    height: 40px;
    margin: .8em 0 0 0;
}

.circle {
    height: 70px;
    width: 70px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    margin: 2em auto 2em auto;
}

.bottomSection {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.06);
    border-radius: 30px 30px 0 0;
}

.bottomSection span {
    cursor: pointer;
    transition: all ease 0.2s;
}

.bottomSection span:hover {
    transform: scale(1.1);
}

.entries {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90%;
    margin: 0 auto;
    overflow-y: scroll;
}

.rightIcons {
    margin: 0.25rem 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: right;
}

.topText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    margin: 0 auto;
}

hr.solid {
    border: 0;
    border-top: 1px solid #8f969e;
    width: 100%
}

.mainText {
    text-align: left;
    margin: 0.3em 0 0 0;

    font-weight: 700;
    color: #333333;
}

.subText {
    text-align: left;
    font-size: 15px;
    margin: 0.3em 0 0.2em 0.1em;
    color: #8f969e;
}

.registerHour {
    display: flex;
    flex-flow: space-between;
}

.firstColumn {
    display: flex;
    flex-direction: column;
}

.secondColumn {
    display: flex;
    flex-direction: column;
    flex: 0 0 10rem;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;
    margin-left: 1rem;
}

.thirdColumn {
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0 10px 0;
}

.carousel {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.arrowRight {
    color: rgb(180, 180, 180);
    width: 24px;
    margin-left: 5px
}

.arrowLeft {
    color: rgb(180, 180, 180);
    width: 24px;
}

.message {
    text-align: center;
    width: 100%;
}

@media screen and (max-width: 690px) {

    .entries {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 90%;
        margin: 0 auto;
        overflow-y: scroll;
        height: 425px;
    }

}
</style>