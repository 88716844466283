<template>
    <div class="main">
        <Header :height="150" />
        <section class="topTextContainer">
            <div class="topText3">
                <span class="material-icons-round iconHeader" @click="backToMain">arrow_back_ios_new</span>
                <h2 class="title">Scoring</h2>
            </div>

        </section>
        <div class="inputContainer">
            <div class="selectableContainer" @click="() => navigation('projects')">
                <Input v-model="store.getters.searchProjectsScore.name" type="withIcon" placeholder="Choose Project"
                    icon="all_inbox" readonly />
            </div>
            <div class="infoManagerCoodinator">
                <span><b>Manager:</b> {{ manager.name }}</span>
            </div>
            <div class="infoManagerCoodinator">
                <span><b>Coordinator:</b> {{ coodenator.name }}</span>
            </div>
        </div>
        <div>
            <div class="container2" v-if="team.length > 0">
                <table class="">
                    <thead>
                        <tr class="">
                            <th>
                                <span class="tableHeader">
                                    Name
                                </span>
                            </th>
                            <template v-for="module in modules" :key="module.name">

                                <th>
                                    <span class="tableHeader">
                                        {{ module.name }}
                                    </span>
                                </th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(user, index) of team" :key="index">
                            <tr>
                                <td class="">
                                    {{ user.name }}
                                </td>
                                <template v-for="(score, index) of user.scores" :key="index">
                                    <td>
                                        <div class=" inputBody ">
                                            <Input type="number" placeholder="Select Score" icon="speed" readonly
                                                v-model="score.score" />
                                        </div>
                                    </td>
                                </template>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="buttom">
            <Button type="edit" text="Submit" :click="Submit" />
        </div>
    </div>
</template>

<script>

import Header from '../../components/Header.vue';
import Button from '../../components/Elements/Button.vue';
import Input from '../../components/Elements/Input.vue';

import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { onMounted, inject, ref } from 'vue';
import { GET, POST } from "../../services/api";
//import { inject,/* onMounted, onUnmounted, ref*/ } from "vue";

export default {
    name: 'Score_adm',
    components: {
        Header,
        Button,
        Input,
    },
    setup() {
        const store = useStore();
        const loader = inject("loading");
        const router = useRouter();
        const modal = inject("modalSuccess");
        const modalError = inject("modalError");

        const coodenator = ref({
            id: 0,
            name: ""
        })

        const manager = ref({
            id: 0,
            name: ""
        })


        const navigation = (path) => {
            router.push(`/search/score/${path}`);
        }

        const modules = ref([])
        const team = ref([])

        const createTeam = (array) => {

            for (const obj of array) {

                const ls = localStorage.getItem('info_dynamik_webapp')
                const json = JSON.parse(ls)
                const User = {
                    id: obj.user.id,
                    name: obj.user.name,
                    scores: [],
                    userRole: json.role,
                    projectId: store.getters.searchProjectsScore.id
                }
                for (const module of modules.value) {
                    const score = {
                        id: module.id,
                        name: module.name,
                        score: ''
                    }

                    User.scores.push(score)

                }

                team.value.push(User)
            }

        }

        const openSuccessModal = async (value) => {
            loader.open();
            modal.open(`${value}`);
            loader.close();
        }

        const openErrorModal = async (value) => {
            loader.open();

            if (value !== undefined) {
                modalError.openError(value);
            } else {
                modalError.openError("Something went wrong");
            }


            loader.close();
        }

        const backToMain = () => {
            router.push('/home')
        }


        onMounted(async () => {
            loader.open();



            if (store.getters.searchProjectsScore.id !== '' && store.getters.searchProjectsScore.id !== undefined) {
                const projectTeam = await GET(`/team/${store.getters.searchProjectsScore.id}/project`)
                const project = await GET(`/projects/${store.getters.searchProjectsScore.id}`)

                manager.value.id = project.data.manager.id
                manager.value.name = project.data.manager.name
                coodenator.value.id = project.data.coordenator.id
                coodenator.value.name = project.data.coordenator.name

                modules.value = await GET(`/module`)

                createTeam(projectTeam)
            }



            loader.close();

        })

        const withScore = (array) => {
            for (const user of array) {
                for (const score of user.scores) {
                    if (score.score === '') {
                        return true
                    }
                }
            }

            return false
        }

        const Submit = async () => {



            const valid = withScore(team.value)

            if (!valid) {
                loader.open();
                try {


                    const response = await POST('/score', team.value)

                    loader.close();

                    if (!response.includes('already')) {
                        openSuccessModal(response);
                        router.push({ path: '/' });
                    } else {
                        openErrorModal(response)
                    }



                } catch (error) {
                    loader.close();
                    openErrorModal();
                }
            } else {

                openErrorModal();
            }



        }


        return {
            navigation,
            store,
            coodenator,
            manager,
            modules,
            team,
            Submit,
            backToMain
        }

    }
}
</script>

<style>
.module h3 {
    margin-left: 15px;
}

.main {
    background-color: #E5E5E5;
}

.container2 {
    width: 80vw;
    background-color: white;

    height: 40vh;
    margin-left: 10%;
    overflow-y: auto;
    display: flex;
    justify-content: start;
    align-items: start;
}

.infoManagerCoodinator {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    gap: 8px
}




.card {

    background: #2F90B0;
    border-radius: 15px;


}


.bodytable {
    display: flex;

}

.inputBody {
    display: flex;
    justify-content: center;
}

.tableHeader {
    padding-left: 4vw;
    padding-right: 5vw;

}

.module {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 2px;
    margin-right: 10px;
}




.topText3 {
    display: flex;
    align-items: center;
}

.topText3 span {
    margin-bottom: 10px;
}


.headerName {
    text-align: center;
    font-size: 12px;
    width: 30vw;
}


.table {
    width: 100%;
}

table,
th,
td {
    margin: 1rem;
    border: 1px solid black;
}


.buttom {
    display: flex;
    align-items: center;
    width: 80vw;
    justify-content: space-evenly;
    margin-left: 10%;
}

@media screen and (max-width: 620px) {
    .tableHeader {
        padding-left: 20vw;
        padding-right: 20vw;

    }

    .inputBody {
        display: flex;
        justify-content: start;
    }

    .container2 {

        display: flex;
        justify-content: start;
    }

    /* Works on Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        height: 18px;
    }

    *::-webkit-scrollbar-track {
        background: scroll;
    }


    ::-webkit-scrollbar-thumb {
        background: #a8a8a8;
        border-radius: 12px;
    }





}
</style>