<template>
    <div class="cardContainerday">
      <div class="cardDay" :style="css">
        <h5> {{ name }} </h5>
      </div>
      <div class="cardDate">
        <p class="date">{{ mydate }}</p>
        <p class="hour" v-if="!isMobile">{{ hour }}h</p>
        <p class="hour" v-else>{{ hour }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Day",
    props: {
      name: String,
      date: String,
      hour: String,
      id: String,
      color : Number
    },
    data() {
      return {
        isMobile: false,
        hours: this.hour
      };
    },
    created() {
      this.isMobile = window.innerWidth < 690 ? true : false;
      window.addEventListener("resize", () => {
        this.isMobile = window.innerWidth < 690 ? true : false;
      });
    },
    methods :{
      colorBG(){
        let colorBg = '';
  
        if(this.color< 8)
          colorBg = '#F26464'
        else if(this.color< 10 && this.color>= 8)
          colorBg = '#E4E75D'
        else if(this.color>= 10)
          colorBg = '#73EB60'
        return colorBg
      }
    },
  
  
    computed:
    {
      css(){
        return {
          '--bg': this.colorBG(),
        }
      },
  
      mydate(){
        const d = this.date;
        const aux = d.split('-')
        return aux[1]+'/'+aux[2] +'/'+aux[0]
  
      }
    }
  }
    
  </script>
  
  
  <style scoped>
  .cardContainerday{
    width: 100px;
    background: #F2F2F2;
    height: 80px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin: 3px;
    display: flex;
    flex-direction: column;
    
  }
  
  .cardDay{
   background: var(--bg);
   border-top-left-radius: 6px;
   border-top-right-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    width: 100px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center
  }
  
  
  .cardDate{
    text-align: center;
    
  }
  
  .date{
    font-size: 10px;
  
    top: -2px;
   
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 9px;
    text-align: center;
    color: #2C2C2C;
  }
  .hour{
    font-weight: bold;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #2C2C2C;
  }
  
  
  @media screen and (max-width: 690px) {
   .cardContainerday{
    width: 58px;
    background: #F2F2F2;
    height: 80px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
  }
  .cardDay{
   background: var(--bg);
   border-top-left-radius: 6px;
   border-top-right-radius: 6px;
   color: white;
  
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    width: 58px;
    height: 17px;
  
    text-align: center;
  
    display: flex;
  
  }
  }
  
  @media screen and (max-width: 360px) {
   .cardContainerday{
    width: 55px;
    background: #F2F2F2;
    height: 70px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
  }
  .cardDay{
   background: var(--bg);
   border-top-left-radius: 6px;
   border-top-right-radius: 6px;
   color: white;
  
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    width: 55px;
    height: 17px;
  
    text-align: center;
  
    display: flex;
  
  }
  }
  </style>
  