<template>
    <div class="bg">
        <section class="topSection">
            <div class="circle">
                <img src="../../assets/logo.png" alt="Logo Dynamik" class="image" />
            </div>
        </section>
        <div class="bottomSection">
            <div class="topText">
            <span class="material-icons-round iconHeader" @click="back">arrow_back_ios_new</span>
            <h1 class="title">{{ route.params.id.charAt(0).toUpperCase() + route.params.id.slice(1) }}</h1>
        </div>
            <Input type="filterProject" placeholder="Type Here" icon="search" v-model="search" />
            <container class="registers">
                <template :key="index" v-for="(item, index) in filterList">
                    <div class="entriesContainer" @click="() => changeSearchValue(item)">
                        <p class="mainText"> {{item.name}}</p>
                        <div class="entry">
                            <p class="subText"> ID: {{(item.id)}}</p>
                        </div>
                        <hr class="solid">
                    </div>
                </template>
            </container>
        </div>
    </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { inject, onMounted, ref } from "vue";
import { GET } from "../../services/api";
import { toDateUS } from "../../services/formatter";
import Input from '../../components/Elements/Input.vue';
import { useStore } from 'vuex';
import { computed } from '@vue/reactivity';
export default {
    name: 'Search',
    components: {
        Input,
    },
    setup(){
        let mutation = "";
        const store = useStore();
        const router = useRouter();
        const data = ref([]);
        const route = useRoute();
        const loader = inject("loading");
        const search = ref();
        
        const filterList = computed(() => {
            if(search.value) {
                return data.value.filter((item) => {
                    return item.name.toLowerCase().includes(search.value.toLowerCase());
                });            
            } else {
               return data.value;
            }
        });

        const changeSearchValue = (value) => {
            store.commit(mutation, value);
            back();
        }

        onMounted( async () => {
            loader.open();
            const id = localStorage.getItem("record_id");

            if(route.params.id === "projects") mutation = id ? "setEditProjects" : "setSearchProjects"
            else if(route.params.id === "activities") mutation = id ? "setEditActivities" : "setSearchActivities"
            else if(route.params.id === "label") mutation = id ? "setEditLabel" : "setSearchLabel"
            else if(route.params.id === "categories") mutation = id ? "setEditCategories" : "setSearchCategories"
            else if(route.params.id === "disciplines") mutation = id ? "setEditDisciplines" : "setSearchDisciplines"
            try{
                data.value = await GET(`${route.params.id}`)
                loader.close();
            } catch (e) {
                console.log(e);
                loader.close();
            }
        })

        const back = () => {
            loader.open();
            const id = localStorage.getItem("record_id");
            if (id){
                backToEdit(id);
                loader.close();
            } else backToMain();
            loader.close();
        }

        const backToMain = async () => {
            loader.open();
            router.push("/");
            loader.close();
        }

        const backToEdit = async (record_id) => {
            loader.open();
            router.push(`/edit/${record_id}`);
            loader.close();
        }
        
        return {back, backToMain, backToEdit, data, toDateUS, changeSearchValue, route, search, filterList}
    },
}
</script>

<style scoped>
    .bg{
        background-color: #2f90b0;
    }
    .topSection{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 0 auto;
    }
    .registers{
        overflow-y: scroll;
    }
    .iconHeader{
        margin: 0 0.3em 0 0;
    }
    .searchBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 1.5em auto 1.5em auto;
    }
    .title{
        margin: .8em 0 1em 0;
        padding: .5em 0 0 0;
    }
    .image{
        width: auto;
        height: 65px;
        margin: 0.2em 0 0 0;
    }
    .circle {
        height: 70px;
        width: 70px;
        background-color: rgb(132,188,204);
        border-radius: 50%;
        margin: 2em auto 2em auto;
    }
    .bottomSection{
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.06);
        border-radius: 30px 30px 0 0;
    }
    .registers{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 80%;
        margin: 0 auto;
        overflow-y: scroll;
    }
    .entriesContainer{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin: 0 auto;
    }
    .topText{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 80%;
        margin: 0 auto;
    }
    hr.solid {
        border: 0;
        border-top: 1px solid #8f969e;
        width: 100%
    }
    
    .mainText{
        margin: 0.3em 0 0 0;
        font-size: 20px;
        font-weight: 700;
        text-align: left;
    }
    .subText{
        font-size: 15px;
        margin: 0.3em 0 0.2em 0.1em;
        color: #8f969e;
        text-align: left;
    }
</style>