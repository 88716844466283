import { createStore } from 'vuex'

const store = createStore({
  state: {
    searchProjects: "",
    searchActivities: "",
    searchLabel: "",
    searchCategories: "",
    searchDisciplines: "",
    registerDescription: "",
    registerWorkedHour: "",
    registerWorkedDate: "",

    editProjects: "",
    editActivities: "",
    editLabel: "",
    editCategories: "",
    editDisciplines: "",
    editDescription: "",
    editWorkedHour: "",
    editWorkedDate: "",

    searchProjectsScore: "",

    modalSuccess: {
      display: false,
      message: "",
    },
    modalError: {
      display: false,
      errorMessage: "",
    },
    modalHowToAccess: {
      display: false,
    },
    modalConfirmation: {
      display: false,
      title: "",
      message: "",
      confirmMessage: "",
      callbackOK: "",
      callbackCancel: ""
    },
    modalConfirmationEntries: {
      display: false,
      title: "",
      message: "",
      confirmMessage: "",
      callbackOK: "",
      callbackCancel: ""
    },
    modalFilterEntries: {
      display: false,
      title: "",
      message: "",
      confirmMessage: "",
      callbackOK: "",
      callbackCancel: ""
    },
    rangeDateFilter: {
      initialDate: "",
      finalDate: ""
    },
    loading: false,

    toggle: false,
    entrie: '',
    day: '',
    filterDate: '',

    role: '',


    //Favorites
    favorites: [],

    modalFavorites: {
      display: false,
    },

    modalFavoritesNickname: {
      display: false,
    },
    modalEditFavoritesNickname: {
      display: false,
      id: "",
    },

    editFavoriteNickname: "",
    registerFavoriteNickname: "",


  },
  getters: {
    searchProjects: (state) => state.searchProjects,
    searchProjectsScore: (state) => state.searchProjectsScore,
    searchActivities: (state) => state.searchActivities,
    searchLabel: (state) => state.searchLabel,
    searchCategories: (state) => state.searchCategories,
    searchDisciplines: (state) => state.searchDisciplines,
    registerDescription: (state) => state.registerDescription,
    registerWorkedHour: (state) => state.registerWorkedHour,
    registerWorkedDate: (state) => state.registerWorkedDate,

    editProjects: (state) => state.editProjects,
    editActivities: (state) => state.editActivities,
    editLabel: (state) => state.editLabel,
    editCategories: (state) => state.editCategories,
    editDisciplines: (state) => state.editDisciplines,
    editDescription: (state) => state.editDescription,
    editWorkedHour: (state) => state.editWorkedHour,
    editWorkedDate: (state) => state.editWorkedDate,

    rangeDateFilter: (state) => state.rangeDateFilter,

    modalSuccess: (state) => state.modalSuccess,
    modalError: (state) => state.modalError,

    modalHowToAccess: (state) => state.modalHowToAccess,
    modalConfirmation: (state) => state.modalConfirmation,
    modalConfirmationEntries: (state) => state.modalConfirmationEntries,
    modalFilterEntries: (state) => state.modalFilterEntries,

    loading: (state) => state.loading,

    toggle: (state) => state.toggle,
    entrie: (state) => state.entrie,
    day: (state) => state.day,
    filterDate: (state) => state.filterDate,

    role: (state) => state.role,


    //Favorites
    favoriteGetter: (state) => state.favorites,
    modalFavorites: (state) => state.modalFavorites,
    modalFavoritesNickname: (state) => state.modalFavoritesNickname,
    modalEditFavoritesNickname: (state) => state.modalEditFavoritesNickname,
    editFavoriteNickname: (state) => state.editFavoriteNickname,
    registerFavoriteNickname: (state) => state.registerFavoriteNickname,

  },
  mutations: {
    setSearchProjects: (state, value) => state.searchProjects = value,
    setSearchProjectsScore: (state, value) => state.searchProjectsScore = value,
    setSearchActivities: (state, value) => state.searchActivities = value,
    setSearchLabel: (state, value) => state.searchLabel = value,
    setSearchCategories: (state, value) => state.searchCategories = value,
    setSearchDisciplines: (state, value) => state.searchDisciplines = value,
    setRegisterDescription: (state, value) => state.registerDescription = value,
    setRegisterWorkedHour: (state, value) => state.registerWorkedHour = value,
    setRegisterWorkedDate: (state, value) => state.registerWorkedDate = value,

    setEditProjects: (state, value) => state.editProjects = value,
    setEditActivities: (state, value) => state.editActivities = value,
    setEditLabel: (state, value) => state.editLabel = value,
    setEditCategories: (state, value) => state.editCategories = value,
    setEditDisciplines: (state, value) => state.editDisciplines = value,
    setEditDescription: (state, value) => state.editDescription = value,
    setEditWorkedHour: (state, value) => state.editWorkedHour = value,
    setEditWorkedDate: (state, value) => state.editWorkedDate = value,

    setRangeDateFilter: (state, value) => state.rangeDateFilter = value,

    setModalSuccess: (state, value) => state.modalSuccess = value,
    setModalError: (state, value) => state.modalError = value,
    setModalHowToAccess: (state, value) => state.modalHowToAccess = value,
    setModalConfirmation: (state, value) => state.modalConfirmation = value,
    setModalConfirmationEntries: (state, value) => state.modalConfirmationEntries = value,
    setModalFilterEntries: (state, value) => state.modalFilterEntries = value,

    loading: (state, value) => state.loading = value,

    toggle: (state, value) => state.toggle = value,

    entrie: (state, value) => state.entrie = value,

    day: (state, value) => state.day = value,

    filterDate: (state, value) => state.filterDate = value,
    setRole: (state, value) => state.role = value,


    //favorites
    setFavorite: (state, value) => state.favorites = value,
    addFavorite: (state, value) => {state.favorites.push(value);},

    setModalFavorites:(state, value) => state.modalFavorites = value,
    setModalFavoritesNickname:(state, value) => state.modalFavoritesNickname = value,
    setModalEditFavoritesNickname:(state, value) => state.modalEditFavoritesNickname = value,

    setRegisterFavoriteNickname: (state, value) => state.registerFavoriteNickname = value,



  },
  actions: {
  },
  modules: {
  }
})

export default store;